/**
 * Based on
 *
 *  - reset.css 2.0 by Eric Meyer
      (public domain)
 *    http://meyerweb.com/eric/tools/css/reset/
 *
 *  - normalize.css 8.0.1 by Nicolas Gallagher and Jonathan Neal
 *    (licensed under MIT)
 *    https://github.com/necolas/normalize.css
 *
 *  - Based on Reboot from Bootstrap 4.2.1
 *    (licensed under MIT)
 *    https://github.com/twbs/bootstrap
 */
/**
 * IE10+ doesn't honor `<meta name="viewport">` in some cases
 */
@-ms-viewport {
  width: device-width; }

/**
   * general reset
   */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, main {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/**
   * HTML5 display-role reset for older browsers
   */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main, summary {
  display: block; }

/**
   * inherit box model for all elements
   */
*,
*::before,
*::after {
  box-sizing: inherit; }

/**
   * html root rules
   * 1. set border-box for inheritance
   * 2. avoid 300ms click delay on touch devices that support the `touch-action`
   *    CSS property
   * 3. Prevent adjustments of font size after orientation changes in IE, on
   *    Windows Phone and iOS.
   * 4. Setting @viewport causes scrollbars to overlap content in IE11 and Edge,
   *    so we force a non-overlapping, non-auto-hiding scrollbar to counteract.
   * 5. Change the default tap highlight to be completely transparent in iOS.
   */
html {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  touch-action: manipulation;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* 4 */
  -ms-overflow-style: scrollbar;
  /* 5 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/**
   * body rules
   * 1. reset line-height to 1
   * 2. set base font-family to sans-serif
   * 3. Set an explicit initial text-align value so that we can later use the
   *    `inherit` value on things like `<th>` elements.
   */
body {
  /* 1 */
  line-height: 1;
  /* 2 */
  font-family: sans-serif;
  /* 3 */
  text-align: left; }

/**
   * Lists
   */
ol, ul {
  list-style: none; }

/**
   * Quotes
   */
blockquote, q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

/**
   * Tables
   */
table {
  border-collapse: collapse;
  border-spacing: 0; }

caption {
  caption-side: bottom; }

/**
   * Table Headers
   * 1. Matches default `<td>` alignment by inheriting from the `<body>`, or the
   *    closest parent with a set `text-align`.
   */
th {
  /* 1 */
  text-align: inherit; }

/**
   * Horizontal Lines
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */
hr {
  /* 1 */
  box-sizing: content-box;
  height: 0;
  /* 2 */
  overflow: visible; }

/**
   * Preformatted Text
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Don't allow content to break outside
   * 3. We have @viewport set which causes scrollbars to overlap content in IE11
   *    and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to
   *    counteract.
   */
pre,
code,
kbd,
samp {
  /* 1 */
  font-family: monospace, monospace; }

pre {
  /* 2 */
  overflow: auto;
  /* 3 */
  -ms-overflow-style: scrollbar; }

/**
   * Links
   * 1. Remove the gray background on active links in IE 10.
   * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
   */
a {
  /* 1 */
  background-color: transparent;
  /* 2 */
  -webkit-text-decoration-skip: objects; }

/**
   * 1. Remove the bottom border in Chrome 57- and Firefox 39-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   * 3. Add explicit cursor to indicate changed behavior.
   * 4. Prevent the text-decoration to be skipped.
   */
abbr[title] {
  /* 1 */
  border-bottom: none;
  /* 2 */
  text-decoration: underline;
  text-decoration: underline dotted;
  /* 3 */
  cursor: help;
  /* 4 */
  text-decoration-skip-ink: none; }

/**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */
b,
strong {
  font-weight: bolder; }

/**
   * Add the correct font size in all browsers.
   */
small {
  font-size: 80%; }

/**
   * Prevent `sub` and `sup` elements from affecting the line height in
   * all browsers.
   */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/**
   * Remove the border on images inside links in IE 10.
   */
img {
  border-style: none; }

/**
   * Hide SVG overflow in IE
   */
svg:not(:root) {
  overflow: hidden; }

/**
   * Remove the default `border-radius` that macOS Chrome adds.
   * Details at https://github.com/twbs/bootstrap/issues/24093
   */
button {
  border-radius: 0; }

/**
   * Work around a Firefox/IE bug where the transparent `button` background
   * results in a loss of the default `button` focus styles.
   * Credit: https://github.com/suitcss/base/
   */
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

/**
   * form element resets
   * 1. Remove the margin in Firefox and Safari
   * 2. inherit font rules
   */
input,
button,
select,
optgroup,
textarea {
  /* 1 */
  margin: 0;
  /* 2 */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
   * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
   *    controls in Android 4.
   * 2. Correct the inability to style clickable types in iOS and Safari.
   */
button,
[type="reset"],
[type="submit"],
[type="button"] {
  /* 2 */
  -webkit-appearance: button; }

/**
   * Remove the default appearance of temporal inputs to avoid a Mobile Safari
   * bug where setting a custom line-height prevents text from being vertically
   * centered within the input.
   * See https://bugs.webkit.org/show_bug.cgi?id=139848
   * and https://github.com/twbs/bootstrap/issues/11266
   */
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

/**
   * 1. Remove the default vertical scrollbar in IE.
   * 2. Textareas should really only resize vertically so they don't break their
   *    (horizontal) containers.
   */
textarea {
  overflow: auto;
  resize: vertical; }

/**
   * Show the overflow in IE.
   */
button,
input {
  overflow: visible; }

/**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   */
button,
select {
  text-transform: none; }

/**
   * Remove the inheritance of word-wrap in Safari.
   * See https://github.com/twbs/bootstrap/issues/24990
   */
select {
  word-wrap: normal; }

/**
   * Remove inner border and padding from Firefox, but don't restore the outline
   * like Normalize.
   */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
   * 1. Add the correct box sizing in IE 10-
   * 2. Remove the padding in IE 10-
   */
input[type="radio"],
input[type="checkbox"] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0; }

/**
   * Suppress the focus outline on elements that cannot be accessed via keyboard.
   * This prevents an unwanted focus outline from appearing around elements that
   * might still respond to pointer events.
   * Credit: https://github.com/suitcss/base
   */
[tabindex="-1"]:focus {
  outline: 0 !important; }

/**
   * Browsers set a default `min-width: min-content` on fieldsets,
   * unlike e.g. `<div>`s, which have `min-width: 0` by default.
   * So we reset that to ensure fieldsets behave more like a standard block element.
   * See https://github.com/twbs/bootstrap/issues/12359
   * and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
   */
fieldset {
  min-width: 0; }

/**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from `fieldset` elements in IE.
   * 3. Set display to block for all browsers
   */
legend {
  /* 1 */
  max-width: 100%;
  white-space: normal;
  /* 2 */
  color: inherit;
  /* 3 */
  display: block; }

/**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */
progress {
  vertical-align: baseline; }

/**
   * Remove the default vertical scrollbar in IE 10+.
   */
textarea {
  overflow: auto; }

/**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */
[type="checkbox"],
[type="radio"] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0; }

/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */
[type="search"] {
  /* 1 */
  -webkit-appearance: textfield;
  /* 2 */
  outline-offset: -2px; }

/**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */
::-webkit-file-upload-button {
  /* 1 */
  -webkit-appearance: button;
  /* 2 */
  font: inherit; }

/**
   * Correct element display for output
   */
output {
  display: inline-block; }

/**
   * Add the correct display in IE 10+.
   */
template {
  display: none; }

[hidden] {
  display: none; }

@font-face {
  font-family: 'fontello';
  src: url("/font/fontello.eot?45961340");
  src: url("/font/fontello.eot?45961340#iefix") format("embedded-opentype"), url("/font/fontello.woff2?45961340") format("woff2"), url("/font/fontello.woff?45961340") format("woff"), url("/font/fontello.ttf?45961340") format("truetype"), url("/font/fontello.svg?45961340#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-cancel:before {
  content: '\e800'; }

.icon-menu:before {
  content: '\f008'; }

.icon-down:before {
  content: '\e801'; }

.icon-linkedin-squared:before {
  content: '\f30c'; }

.icon-grid:before {
  content: '\e802'; }

html,
body {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: #616161;
  font-size: 16px;
  letter-spacing: normal;
  line-height: 1.25;
  text-rendering: optimizeLegibility;
  -moz-font-feature-settings: "kern=1";
  -webkit-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern";
  font-feature-settings: "kern";
  font-kerning: normal;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased !important; }

p {
  font-size: 16px; }
  p strong {
    font-weight: 700; }

a {
  font-size: 16px;
  text-decoration: none; }
  a.menu-item {
    transition: font-size .5s ease;
    color: white;
    font-size: 90%;
    white-space: nowrap;
    text-transform: uppercase;
    padding-bottom: 7px;
    border-bottom: 2px solid transparent;
    transition: border .35s ease, color .35s ease; }
    a.menu-item.active {
      color: #a6bf00;
      border-bottom: 2px solid #a6bf00; }

h1, h2, h3, h4, h5, .h4 {
  font-weight: bold; }

h1 {
  line-height: 1;
  font-size: 5vw;
  color: white; }
  @media (min-width: 768px) {
    h1 {
      font-size: 32px; } }
  @media (min-width: 992px) {
    h1 {
      font-size: 42px; } }
  @media (min-width: 1440px) {
    h1 {
      font-size: 50px; } }

h2 {
  font-size: 36px;
  line-height: 1.45;
  text-transform: uppercase; }
  @media (max-width: 768px) {
    h2 {
      font-size: 26px; } }

h3 {
  font-size: 32px;
  line-height: 1.4; }

h4, .h4 {
  font-size: 28px;
  line-height: 1.35; }
  @media (max-width: 768px) {
    h4, .h4 {
      font-size: 18px; } }

h5 {
  font-size: 24px;
  line-height: 1.3; }

p small {
  font-size: 14px; }

.sticky-outer-wrapper {
  transition: padding-bottom .5s ease; }
  .sticky-outer-wrapper .header {
    background-color: #242934; }
    .sticky-outer-wrapper .header .logo-wrap {
      z-index: 5;
      background-color: #242934; }
      .sticky-outer-wrapper .header .logo-wrap .logo {
        width: 100%;
        display: block;
        margin: 0 auto;
        max-width: 135px;
        transition: max-width .5s ease; }
        @media (min-width: 768px) {
          .sticky-outer-wrapper .header .logo-wrap .logo {
            max-width: 200px;
            margin: 0; } }
    .sticky-outer-wrapper .header .menu-wrap {
      z-index: 0; }
    .sticky-outer-wrapper .header button.mobile_menu {
      color: white;
      font-size: 26px;
      right: 1.5625%;
      top: 15px;
      z-index: 9990;
      background: none;
      border: none;
      cursor: pointer;
      transition: top .35s ease; }
    .sticky-outer-wrapper .header .menu {
      margin-bottom: .4em;
      transition: margin .5s ease; }
      .sticky-outer-wrapper .header .menu li {
        list-style: none; }
        .sticky-outer-wrapper .header .menu li.active a {
          color: #a6bf00;
          border-bottom: 2px solid #a6bf00; }
      @media (max-width: 768px) {
        .sticky-outer-wrapper .header .menu {
          top: 100%;
          left: 0;
          right: 0;
          transform-origin: 0 0;
          transform: scaleY(0) translateY(-25%);
          background-color: #242934;
          border-bottom: 2px solid #a6bf00;
          opacity: 0;
          transition: transform 0.45s cubic-bezier(0.86, 0, 0.07, 1) 0.35s, opacity 0.45s cubic-bezier(0.86, 0, 0.07, 1) 0.35s; }
          .sticky-outer-wrapper .header .menu:before, .sticky-outer-wrapper .header .menu:after {
            width: 50vw;
            content: '';
            position: absolute;
            top: 0;
            bottom: -2px;
            background-color: #242934;
            border-bottom: 2px solid #a6bf00; }
          .sticky-outer-wrapper .header .menu:before {
            left: -50vw; }
          .sticky-outer-wrapper .header .menu:after {
            right: -50vw; }
          .sticky-outer-wrapper .header .menu li a {
            opacity: 0;
            transition: opacity .35s ease; }
          .sticky-outer-wrapper .header .menu.active {
            opacity: 1;
            transform: scaleY(1) translateY(15px);
            transition: transform 0.45s cubic-bezier(0.86, 0, 0.07, 1);
            transition: transform 0.45s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.45s cubic-bezier(0.86, 0, 0.07, 1); }
            .sticky-outer-wrapper .header .menu.active li a {
              opacity: 1;
              transition: opacity .35s ease .45s; } }

.active .header {
  border-bottom: 2px solid #a6bf00; }
  .active .header .logo-wrap .logo {
    max-width: 113px; }
  .active .header button.mobile_menu {
    top: 13px; }
  .active .header ul.menu {
    margin-bottom: .2em; }
    @media (max-width: 768px) {
      .active .header ul.menu.active {
        transform: scaleY(1) translateY(17px); } }
    .active .header ul.menu li a {
      font-size: 80%; }

.home {
  background: url(./img/codeBg.jpg) no-repeat top left;
  background-attachment: fixed;
  background-size: 100% 100%; }
  .home.ios {
    background-size: 100% 100vh; }
  .home .headline {
    z-index: 0; }
    .home .headline a {
      color: white;
      font-size: 24px;
      text-shadow: 2px 1px 2px #000;
      transition: color .35s ease; }
      .home .headline a:hover {
        color: #a6bf00; }

.about {
  background: #fefefe;
  z-index: 2; }
  .about .about_vini img.vini {
    margin: 1em auto;
    max-width: 200px;
    border-radius: 50%; }
  .about .chart ul {
    display: block;
    margin: 1.2em 0 1em 0;
    padding: 0;
    position: relative;
    list-style: none;
    overflow: hidden; }
    .about .chart ul li {
      display: inline-block;
      margin-bottom: 1em;
      padding: 7px 0;
      position: relative;
      font-size: 65%; }
      @media (min-width: 768px) {
        .about .chart ul li {
          font-size: 80%; } }
      .about .chart ul li.label {
        background: #a6bf00;
        width: 100%;
        max-width: 100px;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center; }
      .about .chart ul li.percentage {
        background: #f1f1f1;
        width: calc(100% - 105px);
        text-align: right;
        padding-right: 5px; }
        .about .chart ul li.percentage span {
          transition: right .5s ease-in-out 1s;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 100%; }
  .about .chart.visible ul li.percentage span {
    background: #91a605; }
    .about .chart.visible ul li.percentage span.w_10 {
      right: 95%; }
    .about .chart.visible ul li.percentage span.w_15 {
      right: 90%; }
    .about .chart.visible ul li.percentage span.w_20 {
      right: 85%; }
    .about .chart.visible ul li.percentage span.w_25 {
      right: 80%; }
    .about .chart.visible ul li.percentage span.w_30 {
      right: 75%; }
    .about .chart.visible ul li.percentage span.w_35 {
      right: 70%; }
    .about .chart.visible ul li.percentage span.w_40 {
      right: 65%; }
    .about .chart.visible ul li.percentage span.w_45 {
      right: 60%; }
    .about .chart.visible ul li.percentage span.w_50 {
      right: 55%; }
    .about .chart.visible ul li.percentage span.w_55 {
      right: 50%; }
    .about .chart.visible ul li.percentage span.w_60 {
      right: 45%; }
    .about .chart.visible ul li.percentage span.w_65 {
      right: 40%; }
    .about .chart.visible ul li.percentage span.w_70 {
      right: 35%; }
    .about .chart.visible ul li.percentage span.w_75 {
      right: 30%; }
    .about .chart.visible ul li.percentage span.w_80 {
      right: 25%; }
    .about .chart.visible ul li.percentage span.w_85 {
      right: 20%; }
    .about .chart.visible ul li.percentage span.w_90 {
      right: 15%; }
    .about .chart.visible ul li.percentage span.w_95 {
      right: 10%; }
    .about .chart.visible ul li.percentage span.w_100 {
      right: 5%; }

.work {
  background: #f1f1f1; }
  .work .projects {
    cursor: pointer; }
    .work .projects .overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      background: rgba(255, 255, 255, 0.95);
      transition: opacity .5s ease-in-out;
      text-align: center; }
      .work .projects .overlay h3 {
        text-transform: uppercase;
        margin-bottom: 0;
        padding-bottom: 0;
        font-size: 21px;
        transform: translateY(-150%);
        transition: transform .5s ease, opacity .35s ease; }
        @media (min-width: 1200px) {
          .work .projects .overlay h3 {
            font-size: 24px; } }
      .work .projects .overlay p {
        margin-top: 0;
        margin-bottom: 0;
        color: #a6bf00;
        transform: translateY(-150%);
        transition: transform .5s ease, opacity .35s ease; }
        .work .projects .overlay p.small {
          font-size: 75%;
          margin-top: 0;
          padding-top: 0; }
      .work .projects .overlay a.cta {
        border: 1px solid #a6bf00;
        padding: 10px;
        display: block;
        width: 100%;
        max-width: 150px;
        color: #616161;
        margin: 0 auto;
        text-transform: uppercase;
        transform: translateY(150%);
        background: transparent;
        transition: transform .5s ease, opacity .35s ease, background .35s ease, color .35s ease; }
        @media (max-width: 768px) {
          .work .projects .overlay a.cta {
            transform: translateY(235%); } }
        .work .projects .overlay a.cta:hover {
          background: #a6bf00;
          color: white; }
    .work .projects:hover .overlay {
      opacity: 1;
      right: 0; }
      .work .projects:hover .overlay h3 {
        transform: translateY(0%);
        opacity: 1; }
      .work .projects:hover .overlay p {
        transform: translateY(0%);
        opacity: 1; }
      .work .projects:hover .overlay a.cta {
        transform: translateY(0%);
        opacity: 1; }

.personal-projects {
  background: #fefefe; }

.contact {
  background: #f1f1f1; }
  .contact .mail a {
    font-size: 28px;
    color: inherit; }
    @media (max-width: 768px) {
      .contact .mail a {
        font-size: 18px; } }
    .contact .mail a i {
      font-size: 30px;
      color: #616161;
      transition: color 0.35s ease; }
    .contact .mail a:hover i {
      color: #a6bf00; }
    .contact .mail a.mailto {
      color: #a6bf00;
      transition: color 0.35s ease; }
      .contact .mail a.mailto:hover {
        color: inherit; }

.footer {
  border-bottom: 1px solid #a6bf00;
  background: #242934;
  text-align: center; }
  .footer p {
    color: white;
    margin: 0;
    padding: 0 0 5px 0;
    font-weight: lighter;
    font-size: 60%; }

*, ::after, ::before {
  box-sizing: border-box; }

.container, .container-full {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    width: 540px; } }

@media (min-width: 768px) {
  .container {
    width: 720px; } }

@media (min-width: 992px) {
  .container {
    width: 960px; } }

@media (min-width: 1200px) {
  .container {
    width: 1140px; } }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.col-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xl-1, .offset-1, .offset-sm-1, .offset-md-1, .offset-lg-1, .offset-xl-1, .col-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xl-2, .offset-2, .offset-sm-2, .offset-md-2, .offset-lg-2, .offset-xl-2, .col-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xl-3, .offset-3, .offset-sm-3, .offset-md-3, .offset-lg-3, .offset-xl-3, .col-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xl-4, .offset-4, .offset-sm-4, .offset-md-4, .offset-lg-4, .offset-xl-4, .col-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xl-5, .offset-5, .offset-sm-5, .offset-md-5, .offset-lg-5, .offset-xl-5, .col-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xl-6, .offset-6, .offset-sm-6, .offset-md-6, .offset-lg-6, .offset-xl-6, .col-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xl-7, .offset-7, .offset-sm-7, .offset-md-7, .offset-lg-7, .offset-xl-7, .col-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xl-8, .offset-8, .offset-sm-8, .offset-md-8, .offset-lg-8, .offset-xl-8, .col-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xl-9, .offset-9, .offset-sm-9, .offset-md-9, .offset-lg-9, .offset-xl-9, .col-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xl-10, .offset-10, .offset-sm-10, .offset-md-10, .offset-lg-10, .offset-xl-10, .col-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xl-11, .offset-11, .offset-sm-11, .offset-md-11, .offset-lg-11, .offset-xl-11, .col-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xl-12, .offset-12, .offset-sm-12, .offset-md-12, .offset-lg-12, .offset-xl-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.offset-1 {
  margin-left: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.offset-2 {
  margin-left: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.offset-3 {
  margin-left: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.offset-4 {
  margin-left: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.offset-5 {
  margin-left: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.offset-6 {
  margin-left: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.offset-7 {
  margin-left: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.offset-8 {
  margin-left: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.offset-9 {
  margin-left: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.offset-10 {
  margin-left: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.offset-11 {
  margin-left: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.offset-12 {
  margin-left: 100%; }

@media (min-width: 576px) {
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-12 {
    margin-left: 100%; } }

@media (min-width: 768px) {
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .offset-md-3 {
    margin-left: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .offset-md-6 {
    margin-left: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .offset-md-9 {
    margin-left: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-12 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-12 {
    margin-left: 100%; } }

.justify-content-start {
  justify-content: flex-start; }

.justify-content-end {
  justify-content: flex-end; }

.justify-content-center {
  justify-content: center; }

.justify-content-between {
  justify-content: space-between; }

.justify-content-around {
  justify-content: space-around; }

.justify-content-evenly {
  justify-content: space-evenly; }

.justify-content-initial {
  justify-content: initial; }

.justify-content-inherit {
  justify-content: inherit; }

@media (min-width: 576px) {
  .justify-content-sm-start {
    justify-content: flex-start; }
  .justify-content-sm-end {
    justify-content: flex-end; }
  .justify-content-sm-center {
    justify-content: center; }
  .justify-content-sm-between {
    justify-content: space-between; }
  .justify-content-sm-around {
    justify-content: space-around; }
  .justify-content-sm-evenly {
    justify-content: space-evenly; }
  .justify-content-sm-initial {
    justify-content: initial; }
  .justify-content-sm-inherit {
    justify-content: inherit; } }

@media (min-width: 768px) {
  .justify-content-md-start {
    justify-content: flex-start; }
  .justify-content-md-end {
    justify-content: flex-end; }
  .justify-content-md-center {
    justify-content: center; }
  .justify-content-md-between {
    justify-content: space-between; }
  .justify-content-md-around {
    justify-content: space-around; }
  .justify-content-md-evenly {
    justify-content: space-evenly; }
  .justify-content-md-initial {
    justify-content: initial; }
  .justify-content-md-inherit {
    justify-content: inherit; } }

@media (min-width: 992px) {
  .justify-content-lg-start {
    justify-content: flex-start; }
  .justify-content-lg-end {
    justify-content: flex-end; }
  .justify-content-lg-center {
    justify-content: center; }
  .justify-content-lg-between {
    justify-content: space-between; }
  .justify-content-lg-around {
    justify-content: space-around; }
  .justify-content-lg-evenly {
    justify-content: space-evenly; }
  .justify-content-lg-initial {
    justify-content: initial; }
  .justify-content-lg-inherit {
    justify-content: inherit; } }

@media (min-width: 1200px) {
  .justify-content-xl-start {
    justify-content: flex-start; }
  .justify-content-xl-end {
    justify-content: flex-end; }
  .justify-content-xl-center {
    justify-content: center; }
  .justify-content-xl-between {
    justify-content: space-between; }
  .justify-content-xl-around {
    justify-content: space-around; }
  .justify-content-xl-evenly {
    justify-content: space-evenly; }
  .justify-content-xl-initial {
    justify-content: initial; }
  .justify-content-xl-inherit {
    justify-content: inherit; } }

.align-items-start {
  align-items: flex-start; }

.align-items-end {
  align-items: flex-end; }

.align-items-center {
  align-items: center; }

.align-items-baseline {
  align-items: baseline; }

.align-items-stretch {
  align-items: stretch; }

.align-items-initial {
  align-items: initial; }

.align-items-inherit {
  align-items: inherit; }

@media (min-width: 576px) {
  .align-items-sm-start {
    align-items: flex-start; }
  .align-items-sm-end {
    align-items: flex-end; }
  .align-items-sm-center {
    align-items: center; }
  .align-items-sm-baseline {
    align-items: baseline; }
  .align-items-sm-stretch {
    align-items: stretch; }
  .align-items-sm-initial {
    align-items: initial; }
  .align-items-sm-inherit {
    align-items: inherit; } }

@media (min-width: 768px) {
  .align-items-md-start {
    align-items: flex-start; }
  .align-items-md-end {
    align-items: flex-end; }
  .align-items-md-center {
    align-items: center; }
  .align-items-md-baseline {
    align-items: baseline; }
  .align-items-md-stretch {
    align-items: stretch; }
  .align-items-md-initial {
    align-items: initial; }
  .align-items-md-inherit {
    align-items: inherit; } }

@media (min-width: 992px) {
  .align-items-lg-start {
    align-items: flex-start; }
  .align-items-lg-end {
    align-items: flex-end; }
  .align-items-lg-center {
    align-items: center; }
  .align-items-lg-baseline {
    align-items: baseline; }
  .align-items-lg-stretch {
    align-items: stretch; }
  .align-items-lg-initial {
    align-items: initial; }
  .align-items-lg-inherit {
    align-items: inherit; } }

@media (min-width: 1200px) {
  .align-items-xl-start {
    align-items: flex-start; }
  .align-items-xl-end {
    align-items: flex-end; }
  .align-items-xl-center {
    align-items: center; }
  .align-items-xl-baseline {
    align-items: baseline; }
  .align-items-xl-stretch {
    align-items: stretch; }
  .align-items-xl-initial {
    align-items: initial; }
  .align-items-xl-inherit {
    align-items: inherit; } }

.align-self-start {
  align-self: flex-start; }

.align-self-end {
  align-self: flex-end; }

.align-self-center {
  align-self: center; }

.align-self-baseline {
  align-self: baseline; }

.align-self-stretch {
  align-self: stretch; }

.align-self-auto {
  align-self: auto; }

.align-self-initial {
  align-self: initial; }

.align-self-inherit {
  align-self: inherit; }

@media (min-width: 576px) {
  .align-self-sm-start {
    align-self: flex-start; }
  .align-self-sm-end {
    align-self: flex-end; }
  .align-self-sm-center {
    align-self: center; }
  .align-self-sm-baseline {
    align-self: baseline; }
  .align-self-sm-stretch {
    align-self: stretch; }
  .align-self-sm-auto {
    align-self: auto; }
  .align-self-sm-initial {
    align-self: initial; }
  .align-self-sm-inherit {
    align-self: inherit; } }

@media (min-width: 768px) {
  .align-self-md-start {
    align-self: flex-start; }
  .align-self-md-end {
    align-self: flex-end; }
  .align-self-md-center {
    align-self: center; }
  .align-self-md-baseline {
    align-self: baseline; }
  .align-self-md-stretch {
    align-self: stretch; }
  .align-self-md-auto {
    align-self: auto; }
  .align-self-md-initial {
    align-self: initial; }
  .align-self-md-inherit {
    align-self: inherit; } }

@media (min-width: 992px) {
  .align-self-lg-start {
    align-self: flex-start; }
  .align-self-lg-end {
    align-self: flex-end; }
  .align-self-lg-center {
    align-self: center; }
  .align-self-lg-baseline {
    align-self: baseline; }
  .align-self-lg-stretch {
    align-self: stretch; }
  .align-self-lg-auto {
    align-self: auto; }
  .align-self-lg-initial {
    align-self: initial; }
  .align-self-lg-inherit {
    align-self: inherit; } }

@media (min-width: 1200px) {
  .align-self-xl-start {
    align-self: flex-start; }
  .align-self-xl-end {
    align-self: flex-end; }
  .align-self-xl-center {
    align-self: center; }
  .align-self-xl-baseline {
    align-self: baseline; }
  .align-self-xl-stretch {
    align-self: stretch; }
  .align-self-xl-auto {
    align-self: auto; }
  .align-self-xl-initial {
    align-self: initial; }
  .align-self-xl-inherit {
    align-self: inherit; } }

.position-absolute {
  position: absolute; }

.position-relative {
  position: relative; }

.position-fixed {
  position: fixed; }

.position-unset {
  position: unset; }

.position-static {
  position: static; }

.position-sticky {
  position: sticky; }

.position-initial {
  position: initial; }

.position-inherit {
  position: inherit; }

@media (min-width: 576px) {
  .position-sm-absolute {
    position: absolute; }
  .position-sm-relative {
    position: relative; }
  .position-sm-fixed {
    position: fixed; }
  .position-sm-unset {
    position: unset; }
  .position-sm-static {
    position: static; }
  .position-sm-sticky {
    position: sticky; }
  .position-sm-initial {
    position: initial; }
  .position-sm-inherit {
    position: inherit; } }

@media (min-width: 768px) {
  .position-md-absolute {
    position: absolute; }
  .position-md-relative {
    position: relative; }
  .position-md-fixed {
    position: fixed; }
  .position-md-unset {
    position: unset; }
  .position-md-static {
    position: static; }
  .position-md-sticky {
    position: sticky; }
  .position-md-initial {
    position: initial; }
  .position-md-inherit {
    position: inherit; } }

@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute; }
  .position-lg-relative {
    position: relative; }
  .position-lg-fixed {
    position: fixed; }
  .position-lg-unset {
    position: unset; }
  .position-lg-static {
    position: static; }
  .position-lg-sticky {
    position: sticky; }
  .position-lg-initial {
    position: initial; }
  .position-lg-inherit {
    position: inherit; } }

@media (min-width: 1200px) {
  .position-xl-absolute {
    position: absolute; }
  .position-xl-relative {
    position: relative; }
  .position-xl-fixed {
    position: fixed; }
  .position-xl-unset {
    position: unset; }
  .position-xl-static {
    position: static; }
  .position-xl-sticky {
    position: sticky; }
  .position-xl-initial {
    position: initial; }
  .position-xl-inherit {
    position: inherit; } }

.p-0 {
  padding: 0px; }

.m-0 {
  margin: 0px; }

.pt-0 {
  padding-top: 0px; }

.mt-0 {
  margin-top: 0px; }

.pb-0 {
  padding-bottom: 0px; }

.mb-0 {
  margin-bottom: 0px; }

.px-0 {
  padding-left: 0px;
  padding-right: 0px; }

.mx-0 {
  margin-left: 0px;
  margin-right: 0px; }

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.p-1 {
  padding: 15px; }

.m-1 {
  margin: 15px; }

.pt-1 {
  padding-top: 15px; }

.mt-1 {
  margin-top: 15px; }

.pb-1 {
  padding-bottom: 15px; }

.mb-1 {
  margin-bottom: 15px; }

.px-1 {
  padding-left: 15px;
  padding-right: 15px; }

.mx-1 {
  margin-left: 15px;
  margin-right: 15px; }

.py-1 {
  padding-top: 15px;
  padding-bottom: 15px; }

.my-1 {
  margin-top: 15px;
  margin-bottom: 15px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.p-2 {
  padding: 30px; }

.m-2 {
  margin: 30px; }

.pt-2 {
  padding-top: 30px; }

.mt-2 {
  margin-top: 30px; }

.pb-2 {
  padding-bottom: 30px; }

.mb-2 {
  margin-bottom: 30px; }

.px-2 {
  padding-left: 30px;
  padding-right: 30px; }

.mx-2 {
  margin-left: 30px;
  margin-right: 30px; }

.py-2 {
  padding-top: 30px;
  padding-bottom: 30px; }

.my-2 {
  margin-top: 30px;
  margin-bottom: 30px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.p-3 {
  padding: 45px; }

.m-3 {
  margin: 45px; }

.pt-3 {
  padding-top: 45px; }

.mt-3 {
  margin-top: 45px; }

.pb-3 {
  padding-bottom: 45px; }

.mb-3 {
  margin-bottom: 45px; }

.px-3 {
  padding-left: 45px;
  padding-right: 45px; }

.mx-3 {
  margin-left: 45px;
  margin-right: 45px; }

.py-3 {
  padding-top: 45px;
  padding-bottom: 45px; }

.my-3 {
  margin-top: 45px;
  margin-bottom: 45px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.p-4 {
  padding: 60px; }

.m-4 {
  margin: 60px; }

.pt-4 {
  padding-top: 60px; }

.mt-4 {
  margin-top: 60px; }

.pb-4 {
  padding-bottom: 60px; }

.mb-4 {
  margin-bottom: 60px; }

.px-4 {
  padding-left: 60px;
  padding-right: 60px; }

.mx-4 {
  margin-left: 60px;
  margin-right: 60px; }

.py-4 {
  padding-top: 60px;
  padding-bottom: 60px; }

.my-4 {
  margin-top: 60px;
  margin-bottom: 60px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.p-5 {
  padding: 75px; }

.m-5 {
  margin: 75px; }

.pt-5 {
  padding-top: 75px; }

.mt-5 {
  margin-top: 75px; }

.pb-5 {
  padding-bottom: 75px; }

.mb-5 {
  margin-bottom: 75px; }

.px-5 {
  padding-left: 75px;
  padding-right: 75px; }

.mx-5 {
  margin-left: 75px;
  margin-right: 75px; }

.py-5 {
  padding-top: 75px;
  padding-bottom: 75px; }

.my-5 {
  margin-top: 75px;
  margin-bottom: 75px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.p-6 {
  padding: 90px; }

.m-6 {
  margin: 90px; }

.pt-6 {
  padding-top: 90px; }

.mt-6 {
  margin-top: 90px; }

.pb-6 {
  padding-bottom: 90px; }

.mb-6 {
  margin-bottom: 90px; }

.px-6 {
  padding-left: 90px;
  padding-right: 90px; }

.mx-6 {
  margin-left: 90px;
  margin-right: 90px; }

.py-6 {
  padding-top: 90px;
  padding-bottom: 90px; }

.my-6 {
  margin-top: 90px;
  margin-bottom: 90px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.p-7 {
  padding: 105px; }

.m-7 {
  margin: 105px; }

.pt-7 {
  padding-top: 105px; }

.mt-7 {
  margin-top: 105px; }

.pb-7 {
  padding-bottom: 105px; }

.mb-7 {
  margin-bottom: 105px; }

.px-7 {
  padding-left: 105px;
  padding-right: 105px; }

.mx-7 {
  margin-left: 105px;
  margin-right: 105px; }

.py-7 {
  padding-top: 105px;
  padding-bottom: 105px; }

.my-7 {
  margin-top: 105px;
  margin-bottom: 105px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.p-8 {
  padding: 120px; }

.m-8 {
  margin: 120px; }

.pt-8 {
  padding-top: 120px; }

.mt-8 {
  margin-top: 120px; }

.pb-8 {
  padding-bottom: 120px; }

.mb-8 {
  margin-bottom: 120px; }

.px-8 {
  padding-left: 120px;
  padding-right: 120px; }

.mx-8 {
  margin-left: 120px;
  margin-right: 120px; }

.py-8 {
  padding-top: 120px;
  padding-bottom: 120px; }

.my-8 {
  margin-top: 120px;
  margin-bottom: 120px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.p-9 {
  padding: 135px; }

.m-9 {
  margin: 135px; }

.pt-9 {
  padding-top: 135px; }

.mt-9 {
  margin-top: 135px; }

.pb-9 {
  padding-bottom: 135px; }

.mb-9 {
  margin-bottom: 135px; }

.px-9 {
  padding-left: 135px;
  padding-right: 135px; }

.mx-9 {
  margin-left: 135px;
  margin-right: 135px; }

.py-9 {
  padding-top: 135px;
  padding-bottom: 135px; }

.my-9 {
  margin-top: 135px;
  margin-bottom: 135px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.p-10 {
  padding: 150px; }

.m-10 {
  margin: 150px; }

.pt-10 {
  padding-top: 150px; }

.mt-10 {
  margin-top: 150px; }

.pb-10 {
  padding-bottom: 150px; }

.mb-10 {
  margin-bottom: 150px; }

.px-10 {
  padding-left: 150px;
  padding-right: 150px; }

.mx-10 {
  margin-left: 150px;
  margin-right: 150px; }

.py-10 {
  padding-top: 150px;
  padding-bottom: 150px; }

.my-10 {
  margin-top: 150px;
  margin-bottom: 150px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.p-11 {
  padding: 165px; }

.m-11 {
  margin: 165px; }

.pt-11 {
  padding-top: 165px; }

.mt-11 {
  margin-top: 165px; }

.pb-11 {
  padding-bottom: 165px; }

.mb-11 {
  margin-bottom: 165px; }

.px-11 {
  padding-left: 165px;
  padding-right: 165px; }

.mx-11 {
  margin-left: 165px;
  margin-right: 165px; }

.py-11 {
  padding-top: 165px;
  padding-bottom: 165px; }

.my-11 {
  margin-top: 165px;
  margin-bottom: 165px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.p-12 {
  padding: 180px; }

.m-12 {
  margin: 180px; }

.pt-12 {
  padding-top: 180px; }

.mt-12 {
  margin-top: 180px; }

.pb-12 {
  padding-bottom: 180px; }

.mb-12 {
  margin-bottom: 180px; }

.px-12 {
  padding-left: 180px;
  padding-right: 180px; }

.mx-12 {
  margin-left: 180px;
  margin-right: 180px; }

.py-12 {
  padding-top: 180px;
  padding-bottom: 180px; }

.my-12 {
  margin-top: 180px;
  margin-bottom: 180px; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

@media (min-width: 576px) {
  .p-sm-0 {
    padding: 0px; }
  .m-sm-0 {
    margin: 0px; }
  .pt-sm-0 {
    padding-top: 0px; }
  .mt-sm-0 {
    margin-top: 0px; }
  .pb-sm-0 {
    padding-bottom: 0px; }
  .mb-sm-0 {
    margin-bottom: 0px; }
  .px-sm-0 {
    padding-left: 0px;
    padding-right: 0px; }
  .mx-sm-0 {
    margin-left: 0px;
    margin-right: 0px; }
  .py-sm-0 {
    padding-top: 0px;
    padding-bottom: 0px; }
  .my-sm-0 {
    margin-top: 0px;
    margin-bottom: 0px; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-sm-1 {
    padding: 15px; }
  .m-sm-1 {
    margin: 15px; }
  .pt-sm-1 {
    padding-top: 15px; }
  .mt-sm-1 {
    margin-top: 15px; }
  .pb-sm-1 {
    padding-bottom: 15px; }
  .mb-sm-1 {
    margin-bottom: 15px; }
  .px-sm-1 {
    padding-left: 15px;
    padding-right: 15px; }
  .mx-sm-1 {
    margin-left: 15px;
    margin-right: 15px; }
  .py-sm-1 {
    padding-top: 15px;
    padding-bottom: 15px; }
  .my-sm-1 {
    margin-top: 15px;
    margin-bottom: 15px; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-sm-2 {
    padding: 30px; }
  .m-sm-2 {
    margin: 30px; }
  .pt-sm-2 {
    padding-top: 30px; }
  .mt-sm-2 {
    margin-top: 30px; }
  .pb-sm-2 {
    padding-bottom: 30px; }
  .mb-sm-2 {
    margin-bottom: 30px; }
  .px-sm-2 {
    padding-left: 30px;
    padding-right: 30px; }
  .mx-sm-2 {
    margin-left: 30px;
    margin-right: 30px; }
  .py-sm-2 {
    padding-top: 30px;
    padding-bottom: 30px; }
  .my-sm-2 {
    margin-top: 30px;
    margin-bottom: 30px; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-sm-3 {
    padding: 45px; }
  .m-sm-3 {
    margin: 45px; }
  .pt-sm-3 {
    padding-top: 45px; }
  .mt-sm-3 {
    margin-top: 45px; }
  .pb-sm-3 {
    padding-bottom: 45px; }
  .mb-sm-3 {
    margin-bottom: 45px; }
  .px-sm-3 {
    padding-left: 45px;
    padding-right: 45px; }
  .mx-sm-3 {
    margin-left: 45px;
    margin-right: 45px; }
  .py-sm-3 {
    padding-top: 45px;
    padding-bottom: 45px; }
  .my-sm-3 {
    margin-top: 45px;
    margin-bottom: 45px; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-sm-4 {
    padding: 60px; }
  .m-sm-4 {
    margin: 60px; }
  .pt-sm-4 {
    padding-top: 60px; }
  .mt-sm-4 {
    margin-top: 60px; }
  .pb-sm-4 {
    padding-bottom: 60px; }
  .mb-sm-4 {
    margin-bottom: 60px; }
  .px-sm-4 {
    padding-left: 60px;
    padding-right: 60px; }
  .mx-sm-4 {
    margin-left: 60px;
    margin-right: 60px; }
  .py-sm-4 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .my-sm-4 {
    margin-top: 60px;
    margin-bottom: 60px; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-sm-5 {
    padding: 75px; }
  .m-sm-5 {
    margin: 75px; }
  .pt-sm-5 {
    padding-top: 75px; }
  .mt-sm-5 {
    margin-top: 75px; }
  .pb-sm-5 {
    padding-bottom: 75px; }
  .mb-sm-5 {
    margin-bottom: 75px; }
  .px-sm-5 {
    padding-left: 75px;
    padding-right: 75px; }
  .mx-sm-5 {
    margin-left: 75px;
    margin-right: 75px; }
  .py-sm-5 {
    padding-top: 75px;
    padding-bottom: 75px; }
  .my-sm-5 {
    margin-top: 75px;
    margin-bottom: 75px; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-sm-6 {
    padding: 90px; }
  .m-sm-6 {
    margin: 90px; }
  .pt-sm-6 {
    padding-top: 90px; }
  .mt-sm-6 {
    margin-top: 90px; }
  .pb-sm-6 {
    padding-bottom: 90px; }
  .mb-sm-6 {
    margin-bottom: 90px; }
  .px-sm-6 {
    padding-left: 90px;
    padding-right: 90px; }
  .mx-sm-6 {
    margin-left: 90px;
    margin-right: 90px; }
  .py-sm-6 {
    padding-top: 90px;
    padding-bottom: 90px; }
  .my-sm-6 {
    margin-top: 90px;
    margin-bottom: 90px; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-sm-7 {
    padding: 105px; }
  .m-sm-7 {
    margin: 105px; }
  .pt-sm-7 {
    padding-top: 105px; }
  .mt-sm-7 {
    margin-top: 105px; }
  .pb-sm-7 {
    padding-bottom: 105px; }
  .mb-sm-7 {
    margin-bottom: 105px; }
  .px-sm-7 {
    padding-left: 105px;
    padding-right: 105px; }
  .mx-sm-7 {
    margin-left: 105px;
    margin-right: 105px; }
  .py-sm-7 {
    padding-top: 105px;
    padding-bottom: 105px; }
  .my-sm-7 {
    margin-top: 105px;
    margin-bottom: 105px; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-sm-8 {
    padding: 120px; }
  .m-sm-8 {
    margin: 120px; }
  .pt-sm-8 {
    padding-top: 120px; }
  .mt-sm-8 {
    margin-top: 120px; }
  .pb-sm-8 {
    padding-bottom: 120px; }
  .mb-sm-8 {
    margin-bottom: 120px; }
  .px-sm-8 {
    padding-left: 120px;
    padding-right: 120px; }
  .mx-sm-8 {
    margin-left: 120px;
    margin-right: 120px; }
  .py-sm-8 {
    padding-top: 120px;
    padding-bottom: 120px; }
  .my-sm-8 {
    margin-top: 120px;
    margin-bottom: 120px; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-sm-9 {
    padding: 135px; }
  .m-sm-9 {
    margin: 135px; }
  .pt-sm-9 {
    padding-top: 135px; }
  .mt-sm-9 {
    margin-top: 135px; }
  .pb-sm-9 {
    padding-bottom: 135px; }
  .mb-sm-9 {
    margin-bottom: 135px; }
  .px-sm-9 {
    padding-left: 135px;
    padding-right: 135px; }
  .mx-sm-9 {
    margin-left: 135px;
    margin-right: 135px; }
  .py-sm-9 {
    padding-top: 135px;
    padding-bottom: 135px; }
  .my-sm-9 {
    margin-top: 135px;
    margin-bottom: 135px; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-sm-10 {
    padding: 150px; }
  .m-sm-10 {
    margin: 150px; }
  .pt-sm-10 {
    padding-top: 150px; }
  .mt-sm-10 {
    margin-top: 150px; }
  .pb-sm-10 {
    padding-bottom: 150px; }
  .mb-sm-10 {
    margin-bottom: 150px; }
  .px-sm-10 {
    padding-left: 150px;
    padding-right: 150px; }
  .mx-sm-10 {
    margin-left: 150px;
    margin-right: 150px; }
  .py-sm-10 {
    padding-top: 150px;
    padding-bottom: 150px; }
  .my-sm-10 {
    margin-top: 150px;
    margin-bottom: 150px; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-sm-11 {
    padding: 165px; }
  .m-sm-11 {
    margin: 165px; }
  .pt-sm-11 {
    padding-top: 165px; }
  .mt-sm-11 {
    margin-top: 165px; }
  .pb-sm-11 {
    padding-bottom: 165px; }
  .mb-sm-11 {
    margin-bottom: 165px; }
  .px-sm-11 {
    padding-left: 165px;
    padding-right: 165px; }
  .mx-sm-11 {
    margin-left: 165px;
    margin-right: 165px; }
  .py-sm-11 {
    padding-top: 165px;
    padding-bottom: 165px; }
  .my-sm-11 {
    margin-top: 165px;
    margin-bottom: 165px; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-sm-12 {
    padding: 180px; }
  .m-sm-12 {
    margin: 180px; }
  .pt-sm-12 {
    padding-top: 180px; }
  .mt-sm-12 {
    margin-top: 180px; }
  .pb-sm-12 {
    padding-bottom: 180px; }
  .mb-sm-12 {
    margin-bottom: 180px; }
  .px-sm-12 {
    padding-left: 180px;
    padding-right: 180px; }
  .mx-sm-12 {
    margin-left: 180px;
    margin-right: 180px; }
  .py-sm-12 {
    padding-top: 180px;
    padding-bottom: 180px; }
  .my-sm-12 {
    margin-top: 180px;
    margin-bottom: 180px; }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 768px) {
  .p-md-0 {
    padding: 0px; }
  .m-md-0 {
    margin: 0px; }
  .pt-md-0 {
    padding-top: 0px; }
  .mt-md-0 {
    margin-top: 0px; }
  .pb-md-0 {
    padding-bottom: 0px; }
  .mb-md-0 {
    margin-bottom: 0px; }
  .px-md-0 {
    padding-left: 0px;
    padding-right: 0px; }
  .mx-md-0 {
    margin-left: 0px;
    margin-right: 0px; }
  .py-md-0 {
    padding-top: 0px;
    padding-bottom: 0px; }
  .my-md-0 {
    margin-top: 0px;
    margin-bottom: 0px; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-md-1 {
    padding: 15px; }
  .m-md-1 {
    margin: 15px; }
  .pt-md-1 {
    padding-top: 15px; }
  .mt-md-1 {
    margin-top: 15px; }
  .pb-md-1 {
    padding-bottom: 15px; }
  .mb-md-1 {
    margin-bottom: 15px; }
  .px-md-1 {
    padding-left: 15px;
    padding-right: 15px; }
  .mx-md-1 {
    margin-left: 15px;
    margin-right: 15px; }
  .py-md-1 {
    padding-top: 15px;
    padding-bottom: 15px; }
  .my-md-1 {
    margin-top: 15px;
    margin-bottom: 15px; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-md-2 {
    padding: 30px; }
  .m-md-2 {
    margin: 30px; }
  .pt-md-2 {
    padding-top: 30px; }
  .mt-md-2 {
    margin-top: 30px; }
  .pb-md-2 {
    padding-bottom: 30px; }
  .mb-md-2 {
    margin-bottom: 30px; }
  .px-md-2 {
    padding-left: 30px;
    padding-right: 30px; }
  .mx-md-2 {
    margin-left: 30px;
    margin-right: 30px; }
  .py-md-2 {
    padding-top: 30px;
    padding-bottom: 30px; }
  .my-md-2 {
    margin-top: 30px;
    margin-bottom: 30px; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-md-3 {
    padding: 45px; }
  .m-md-3 {
    margin: 45px; }
  .pt-md-3 {
    padding-top: 45px; }
  .mt-md-3 {
    margin-top: 45px; }
  .pb-md-3 {
    padding-bottom: 45px; }
  .mb-md-3 {
    margin-bottom: 45px; }
  .px-md-3 {
    padding-left: 45px;
    padding-right: 45px; }
  .mx-md-3 {
    margin-left: 45px;
    margin-right: 45px; }
  .py-md-3 {
    padding-top: 45px;
    padding-bottom: 45px; }
  .my-md-3 {
    margin-top: 45px;
    margin-bottom: 45px; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-md-4 {
    padding: 60px; }
  .m-md-4 {
    margin: 60px; }
  .pt-md-4 {
    padding-top: 60px; }
  .mt-md-4 {
    margin-top: 60px; }
  .pb-md-4 {
    padding-bottom: 60px; }
  .mb-md-4 {
    margin-bottom: 60px; }
  .px-md-4 {
    padding-left: 60px;
    padding-right: 60px; }
  .mx-md-4 {
    margin-left: 60px;
    margin-right: 60px; }
  .py-md-4 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .my-md-4 {
    margin-top: 60px;
    margin-bottom: 60px; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-md-5 {
    padding: 75px; }
  .m-md-5 {
    margin: 75px; }
  .pt-md-5 {
    padding-top: 75px; }
  .mt-md-5 {
    margin-top: 75px; }
  .pb-md-5 {
    padding-bottom: 75px; }
  .mb-md-5 {
    margin-bottom: 75px; }
  .px-md-5 {
    padding-left: 75px;
    padding-right: 75px; }
  .mx-md-5 {
    margin-left: 75px;
    margin-right: 75px; }
  .py-md-5 {
    padding-top: 75px;
    padding-bottom: 75px; }
  .my-md-5 {
    margin-top: 75px;
    margin-bottom: 75px; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-md-6 {
    padding: 90px; }
  .m-md-6 {
    margin: 90px; }
  .pt-md-6 {
    padding-top: 90px; }
  .mt-md-6 {
    margin-top: 90px; }
  .pb-md-6 {
    padding-bottom: 90px; }
  .mb-md-6 {
    margin-bottom: 90px; }
  .px-md-6 {
    padding-left: 90px;
    padding-right: 90px; }
  .mx-md-6 {
    margin-left: 90px;
    margin-right: 90px; }
  .py-md-6 {
    padding-top: 90px;
    padding-bottom: 90px; }
  .my-md-6 {
    margin-top: 90px;
    margin-bottom: 90px; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-md-7 {
    padding: 105px; }
  .m-md-7 {
    margin: 105px; }
  .pt-md-7 {
    padding-top: 105px; }
  .mt-md-7 {
    margin-top: 105px; }
  .pb-md-7 {
    padding-bottom: 105px; }
  .mb-md-7 {
    margin-bottom: 105px; }
  .px-md-7 {
    padding-left: 105px;
    padding-right: 105px; }
  .mx-md-7 {
    margin-left: 105px;
    margin-right: 105px; }
  .py-md-7 {
    padding-top: 105px;
    padding-bottom: 105px; }
  .my-md-7 {
    margin-top: 105px;
    margin-bottom: 105px; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-md-8 {
    padding: 120px; }
  .m-md-8 {
    margin: 120px; }
  .pt-md-8 {
    padding-top: 120px; }
  .mt-md-8 {
    margin-top: 120px; }
  .pb-md-8 {
    padding-bottom: 120px; }
  .mb-md-8 {
    margin-bottom: 120px; }
  .px-md-8 {
    padding-left: 120px;
    padding-right: 120px; }
  .mx-md-8 {
    margin-left: 120px;
    margin-right: 120px; }
  .py-md-8 {
    padding-top: 120px;
    padding-bottom: 120px; }
  .my-md-8 {
    margin-top: 120px;
    margin-bottom: 120px; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-md-9 {
    padding: 135px; }
  .m-md-9 {
    margin: 135px; }
  .pt-md-9 {
    padding-top: 135px; }
  .mt-md-9 {
    margin-top: 135px; }
  .pb-md-9 {
    padding-bottom: 135px; }
  .mb-md-9 {
    margin-bottom: 135px; }
  .px-md-9 {
    padding-left: 135px;
    padding-right: 135px; }
  .mx-md-9 {
    margin-left: 135px;
    margin-right: 135px; }
  .py-md-9 {
    padding-top: 135px;
    padding-bottom: 135px; }
  .my-md-9 {
    margin-top: 135px;
    margin-bottom: 135px; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-md-10 {
    padding: 150px; }
  .m-md-10 {
    margin: 150px; }
  .pt-md-10 {
    padding-top: 150px; }
  .mt-md-10 {
    margin-top: 150px; }
  .pb-md-10 {
    padding-bottom: 150px; }
  .mb-md-10 {
    margin-bottom: 150px; }
  .px-md-10 {
    padding-left: 150px;
    padding-right: 150px; }
  .mx-md-10 {
    margin-left: 150px;
    margin-right: 150px; }
  .py-md-10 {
    padding-top: 150px;
    padding-bottom: 150px; }
  .my-md-10 {
    margin-top: 150px;
    margin-bottom: 150px; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-md-11 {
    padding: 165px; }
  .m-md-11 {
    margin: 165px; }
  .pt-md-11 {
    padding-top: 165px; }
  .mt-md-11 {
    margin-top: 165px; }
  .pb-md-11 {
    padding-bottom: 165px; }
  .mb-md-11 {
    margin-bottom: 165px; }
  .px-md-11 {
    padding-left: 165px;
    padding-right: 165px; }
  .mx-md-11 {
    margin-left: 165px;
    margin-right: 165px; }
  .py-md-11 {
    padding-top: 165px;
    padding-bottom: 165px; }
  .my-md-11 {
    margin-top: 165px;
    margin-bottom: 165px; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-md-12 {
    padding: 180px; }
  .m-md-12 {
    margin: 180px; }
  .pt-md-12 {
    padding-top: 180px; }
  .mt-md-12 {
    margin-top: 180px; }
  .pb-md-12 {
    padding-bottom: 180px; }
  .mb-md-12 {
    margin-bottom: 180px; }
  .px-md-12 {
    padding-left: 180px;
    padding-right: 180px; }
  .mx-md-12 {
    margin-left: 180px;
    margin-right: 180px; }
  .py-md-12 {
    padding-top: 180px;
    padding-bottom: 180px; }
  .my-md-12 {
    margin-top: 180px;
    margin-bottom: 180px; }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 992px) {
  .p-lg-0 {
    padding: 0px; }
  .m-lg-0 {
    margin: 0px; }
  .pt-lg-0 {
    padding-top: 0px; }
  .mt-lg-0 {
    margin-top: 0px; }
  .pb-lg-0 {
    padding-bottom: 0px; }
  .mb-lg-0 {
    margin-bottom: 0px; }
  .px-lg-0 {
    padding-left: 0px;
    padding-right: 0px; }
  .mx-lg-0 {
    margin-left: 0px;
    margin-right: 0px; }
  .py-lg-0 {
    padding-top: 0px;
    padding-bottom: 0px; }
  .my-lg-0 {
    margin-top: 0px;
    margin-bottom: 0px; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-lg-1 {
    padding: 15px; }
  .m-lg-1 {
    margin: 15px; }
  .pt-lg-1 {
    padding-top: 15px; }
  .mt-lg-1 {
    margin-top: 15px; }
  .pb-lg-1 {
    padding-bottom: 15px; }
  .mb-lg-1 {
    margin-bottom: 15px; }
  .px-lg-1 {
    padding-left: 15px;
    padding-right: 15px; }
  .mx-lg-1 {
    margin-left: 15px;
    margin-right: 15px; }
  .py-lg-1 {
    padding-top: 15px;
    padding-bottom: 15px; }
  .my-lg-1 {
    margin-top: 15px;
    margin-bottom: 15px; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-lg-2 {
    padding: 30px; }
  .m-lg-2 {
    margin: 30px; }
  .pt-lg-2 {
    padding-top: 30px; }
  .mt-lg-2 {
    margin-top: 30px; }
  .pb-lg-2 {
    padding-bottom: 30px; }
  .mb-lg-2 {
    margin-bottom: 30px; }
  .px-lg-2 {
    padding-left: 30px;
    padding-right: 30px; }
  .mx-lg-2 {
    margin-left: 30px;
    margin-right: 30px; }
  .py-lg-2 {
    padding-top: 30px;
    padding-bottom: 30px; }
  .my-lg-2 {
    margin-top: 30px;
    margin-bottom: 30px; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-lg-3 {
    padding: 45px; }
  .m-lg-3 {
    margin: 45px; }
  .pt-lg-3 {
    padding-top: 45px; }
  .mt-lg-3 {
    margin-top: 45px; }
  .pb-lg-3 {
    padding-bottom: 45px; }
  .mb-lg-3 {
    margin-bottom: 45px; }
  .px-lg-3 {
    padding-left: 45px;
    padding-right: 45px; }
  .mx-lg-3 {
    margin-left: 45px;
    margin-right: 45px; }
  .py-lg-3 {
    padding-top: 45px;
    padding-bottom: 45px; }
  .my-lg-3 {
    margin-top: 45px;
    margin-bottom: 45px; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-lg-4 {
    padding: 60px; }
  .m-lg-4 {
    margin: 60px; }
  .pt-lg-4 {
    padding-top: 60px; }
  .mt-lg-4 {
    margin-top: 60px; }
  .pb-lg-4 {
    padding-bottom: 60px; }
  .mb-lg-4 {
    margin-bottom: 60px; }
  .px-lg-4 {
    padding-left: 60px;
    padding-right: 60px; }
  .mx-lg-4 {
    margin-left: 60px;
    margin-right: 60px; }
  .py-lg-4 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .my-lg-4 {
    margin-top: 60px;
    margin-bottom: 60px; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-lg-5 {
    padding: 75px; }
  .m-lg-5 {
    margin: 75px; }
  .pt-lg-5 {
    padding-top: 75px; }
  .mt-lg-5 {
    margin-top: 75px; }
  .pb-lg-5 {
    padding-bottom: 75px; }
  .mb-lg-5 {
    margin-bottom: 75px; }
  .px-lg-5 {
    padding-left: 75px;
    padding-right: 75px; }
  .mx-lg-5 {
    margin-left: 75px;
    margin-right: 75px; }
  .py-lg-5 {
    padding-top: 75px;
    padding-bottom: 75px; }
  .my-lg-5 {
    margin-top: 75px;
    margin-bottom: 75px; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-lg-6 {
    padding: 90px; }
  .m-lg-6 {
    margin: 90px; }
  .pt-lg-6 {
    padding-top: 90px; }
  .mt-lg-6 {
    margin-top: 90px; }
  .pb-lg-6 {
    padding-bottom: 90px; }
  .mb-lg-6 {
    margin-bottom: 90px; }
  .px-lg-6 {
    padding-left: 90px;
    padding-right: 90px; }
  .mx-lg-6 {
    margin-left: 90px;
    margin-right: 90px; }
  .py-lg-6 {
    padding-top: 90px;
    padding-bottom: 90px; }
  .my-lg-6 {
    margin-top: 90px;
    margin-bottom: 90px; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-lg-7 {
    padding: 105px; }
  .m-lg-7 {
    margin: 105px; }
  .pt-lg-7 {
    padding-top: 105px; }
  .mt-lg-7 {
    margin-top: 105px; }
  .pb-lg-7 {
    padding-bottom: 105px; }
  .mb-lg-7 {
    margin-bottom: 105px; }
  .px-lg-7 {
    padding-left: 105px;
    padding-right: 105px; }
  .mx-lg-7 {
    margin-left: 105px;
    margin-right: 105px; }
  .py-lg-7 {
    padding-top: 105px;
    padding-bottom: 105px; }
  .my-lg-7 {
    margin-top: 105px;
    margin-bottom: 105px; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-lg-8 {
    padding: 120px; }
  .m-lg-8 {
    margin: 120px; }
  .pt-lg-8 {
    padding-top: 120px; }
  .mt-lg-8 {
    margin-top: 120px; }
  .pb-lg-8 {
    padding-bottom: 120px; }
  .mb-lg-8 {
    margin-bottom: 120px; }
  .px-lg-8 {
    padding-left: 120px;
    padding-right: 120px; }
  .mx-lg-8 {
    margin-left: 120px;
    margin-right: 120px; }
  .py-lg-8 {
    padding-top: 120px;
    padding-bottom: 120px; }
  .my-lg-8 {
    margin-top: 120px;
    margin-bottom: 120px; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-lg-9 {
    padding: 135px; }
  .m-lg-9 {
    margin: 135px; }
  .pt-lg-9 {
    padding-top: 135px; }
  .mt-lg-9 {
    margin-top: 135px; }
  .pb-lg-9 {
    padding-bottom: 135px; }
  .mb-lg-9 {
    margin-bottom: 135px; }
  .px-lg-9 {
    padding-left: 135px;
    padding-right: 135px; }
  .mx-lg-9 {
    margin-left: 135px;
    margin-right: 135px; }
  .py-lg-9 {
    padding-top: 135px;
    padding-bottom: 135px; }
  .my-lg-9 {
    margin-top: 135px;
    margin-bottom: 135px; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-lg-10 {
    padding: 150px; }
  .m-lg-10 {
    margin: 150px; }
  .pt-lg-10 {
    padding-top: 150px; }
  .mt-lg-10 {
    margin-top: 150px; }
  .pb-lg-10 {
    padding-bottom: 150px; }
  .mb-lg-10 {
    margin-bottom: 150px; }
  .px-lg-10 {
    padding-left: 150px;
    padding-right: 150px; }
  .mx-lg-10 {
    margin-left: 150px;
    margin-right: 150px; }
  .py-lg-10 {
    padding-top: 150px;
    padding-bottom: 150px; }
  .my-lg-10 {
    margin-top: 150px;
    margin-bottom: 150px; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-lg-11 {
    padding: 165px; }
  .m-lg-11 {
    margin: 165px; }
  .pt-lg-11 {
    padding-top: 165px; }
  .mt-lg-11 {
    margin-top: 165px; }
  .pb-lg-11 {
    padding-bottom: 165px; }
  .mb-lg-11 {
    margin-bottom: 165px; }
  .px-lg-11 {
    padding-left: 165px;
    padding-right: 165px; }
  .mx-lg-11 {
    margin-left: 165px;
    margin-right: 165px; }
  .py-lg-11 {
    padding-top: 165px;
    padding-bottom: 165px; }
  .my-lg-11 {
    margin-top: 165px;
    margin-bottom: 165px; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-lg-12 {
    padding: 180px; }
  .m-lg-12 {
    margin: 180px; }
  .pt-lg-12 {
    padding-top: 180px; }
  .mt-lg-12 {
    margin-top: 180px; }
  .pb-lg-12 {
    padding-bottom: 180px; }
  .mb-lg-12 {
    margin-bottom: 180px; }
  .px-lg-12 {
    padding-left: 180px;
    padding-right: 180px; }
  .mx-lg-12 {
    margin-left: 180px;
    margin-right: 180px; }
  .py-lg-12 {
    padding-top: 180px;
    padding-bottom: 180px; }
  .my-lg-12 {
    margin-top: 180px;
    margin-bottom: 180px; }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 1200px) {
  .p-xl-0 {
    padding: 0px; }
  .m-xl-0 {
    margin: 0px; }
  .pt-xl-0 {
    padding-top: 0px; }
  .mt-xl-0 {
    margin-top: 0px; }
  .pb-xl-0 {
    padding-bottom: 0px; }
  .mb-xl-0 {
    margin-bottom: 0px; }
  .px-xl-0 {
    padding-left: 0px;
    padding-right: 0px; }
  .mx-xl-0 {
    margin-left: 0px;
    margin-right: 0px; }
  .py-xl-0 {
    padding-top: 0px;
    padding-bottom: 0px; }
  .my-xl-0 {
    margin-top: 0px;
    margin-bottom: 0px; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-xl-1 {
    padding: 15px; }
  .m-xl-1 {
    margin: 15px; }
  .pt-xl-1 {
    padding-top: 15px; }
  .mt-xl-1 {
    margin-top: 15px; }
  .pb-xl-1 {
    padding-bottom: 15px; }
  .mb-xl-1 {
    margin-bottom: 15px; }
  .px-xl-1 {
    padding-left: 15px;
    padding-right: 15px; }
  .mx-xl-1 {
    margin-left: 15px;
    margin-right: 15px; }
  .py-xl-1 {
    padding-top: 15px;
    padding-bottom: 15px; }
  .my-xl-1 {
    margin-top: 15px;
    margin-bottom: 15px; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-xl-2 {
    padding: 30px; }
  .m-xl-2 {
    margin: 30px; }
  .pt-xl-2 {
    padding-top: 30px; }
  .mt-xl-2 {
    margin-top: 30px; }
  .pb-xl-2 {
    padding-bottom: 30px; }
  .mb-xl-2 {
    margin-bottom: 30px; }
  .px-xl-2 {
    padding-left: 30px;
    padding-right: 30px; }
  .mx-xl-2 {
    margin-left: 30px;
    margin-right: 30px; }
  .py-xl-2 {
    padding-top: 30px;
    padding-bottom: 30px; }
  .my-xl-2 {
    margin-top: 30px;
    margin-bottom: 30px; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-xl-3 {
    padding: 45px; }
  .m-xl-3 {
    margin: 45px; }
  .pt-xl-3 {
    padding-top: 45px; }
  .mt-xl-3 {
    margin-top: 45px; }
  .pb-xl-3 {
    padding-bottom: 45px; }
  .mb-xl-3 {
    margin-bottom: 45px; }
  .px-xl-3 {
    padding-left: 45px;
    padding-right: 45px; }
  .mx-xl-3 {
    margin-left: 45px;
    margin-right: 45px; }
  .py-xl-3 {
    padding-top: 45px;
    padding-bottom: 45px; }
  .my-xl-3 {
    margin-top: 45px;
    margin-bottom: 45px; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-xl-4 {
    padding: 60px; }
  .m-xl-4 {
    margin: 60px; }
  .pt-xl-4 {
    padding-top: 60px; }
  .mt-xl-4 {
    margin-top: 60px; }
  .pb-xl-4 {
    padding-bottom: 60px; }
  .mb-xl-4 {
    margin-bottom: 60px; }
  .px-xl-4 {
    padding-left: 60px;
    padding-right: 60px; }
  .mx-xl-4 {
    margin-left: 60px;
    margin-right: 60px; }
  .py-xl-4 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .my-xl-4 {
    margin-top: 60px;
    margin-bottom: 60px; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-xl-5 {
    padding: 75px; }
  .m-xl-5 {
    margin: 75px; }
  .pt-xl-5 {
    padding-top: 75px; }
  .mt-xl-5 {
    margin-top: 75px; }
  .pb-xl-5 {
    padding-bottom: 75px; }
  .mb-xl-5 {
    margin-bottom: 75px; }
  .px-xl-5 {
    padding-left: 75px;
    padding-right: 75px; }
  .mx-xl-5 {
    margin-left: 75px;
    margin-right: 75px; }
  .py-xl-5 {
    padding-top: 75px;
    padding-bottom: 75px; }
  .my-xl-5 {
    margin-top: 75px;
    margin-bottom: 75px; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-xl-6 {
    padding: 90px; }
  .m-xl-6 {
    margin: 90px; }
  .pt-xl-6 {
    padding-top: 90px; }
  .mt-xl-6 {
    margin-top: 90px; }
  .pb-xl-6 {
    padding-bottom: 90px; }
  .mb-xl-6 {
    margin-bottom: 90px; }
  .px-xl-6 {
    padding-left: 90px;
    padding-right: 90px; }
  .mx-xl-6 {
    margin-left: 90px;
    margin-right: 90px; }
  .py-xl-6 {
    padding-top: 90px;
    padding-bottom: 90px; }
  .my-xl-6 {
    margin-top: 90px;
    margin-bottom: 90px; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-xl-7 {
    padding: 105px; }
  .m-xl-7 {
    margin: 105px; }
  .pt-xl-7 {
    padding-top: 105px; }
  .mt-xl-7 {
    margin-top: 105px; }
  .pb-xl-7 {
    padding-bottom: 105px; }
  .mb-xl-7 {
    margin-bottom: 105px; }
  .px-xl-7 {
    padding-left: 105px;
    padding-right: 105px; }
  .mx-xl-7 {
    margin-left: 105px;
    margin-right: 105px; }
  .py-xl-7 {
    padding-top: 105px;
    padding-bottom: 105px; }
  .my-xl-7 {
    margin-top: 105px;
    margin-bottom: 105px; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-xl-8 {
    padding: 120px; }
  .m-xl-8 {
    margin: 120px; }
  .pt-xl-8 {
    padding-top: 120px; }
  .mt-xl-8 {
    margin-top: 120px; }
  .pb-xl-8 {
    padding-bottom: 120px; }
  .mb-xl-8 {
    margin-bottom: 120px; }
  .px-xl-8 {
    padding-left: 120px;
    padding-right: 120px; }
  .mx-xl-8 {
    margin-left: 120px;
    margin-right: 120px; }
  .py-xl-8 {
    padding-top: 120px;
    padding-bottom: 120px; }
  .my-xl-8 {
    margin-top: 120px;
    margin-bottom: 120px; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-xl-9 {
    padding: 135px; }
  .m-xl-9 {
    margin: 135px; }
  .pt-xl-9 {
    padding-top: 135px; }
  .mt-xl-9 {
    margin-top: 135px; }
  .pb-xl-9 {
    padding-bottom: 135px; }
  .mb-xl-9 {
    margin-bottom: 135px; }
  .px-xl-9 {
    padding-left: 135px;
    padding-right: 135px; }
  .mx-xl-9 {
    margin-left: 135px;
    margin-right: 135px; }
  .py-xl-9 {
    padding-top: 135px;
    padding-bottom: 135px; }
  .my-xl-9 {
    margin-top: 135px;
    margin-bottom: 135px; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-xl-10 {
    padding: 150px; }
  .m-xl-10 {
    margin: 150px; }
  .pt-xl-10 {
    padding-top: 150px; }
  .mt-xl-10 {
    margin-top: 150px; }
  .pb-xl-10 {
    padding-bottom: 150px; }
  .mb-xl-10 {
    margin-bottom: 150px; }
  .px-xl-10 {
    padding-left: 150px;
    padding-right: 150px; }
  .mx-xl-10 {
    margin-left: 150px;
    margin-right: 150px; }
  .py-xl-10 {
    padding-top: 150px;
    padding-bottom: 150px; }
  .my-xl-10 {
    margin-top: 150px;
    margin-bottom: 150px; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-xl-11 {
    padding: 165px; }
  .m-xl-11 {
    margin: 165px; }
  .pt-xl-11 {
    padding-top: 165px; }
  .mt-xl-11 {
    margin-top: 165px; }
  .pb-xl-11 {
    padding-bottom: 165px; }
  .mb-xl-11 {
    margin-bottom: 165px; }
  .px-xl-11 {
    padding-left: 165px;
    padding-right: 165px; }
  .mx-xl-11 {
    margin-left: 165px;
    margin-right: 165px; }
  .py-xl-11 {
    padding-top: 165px;
    padding-bottom: 165px; }
  .my-xl-11 {
    margin-top: 165px;
    margin-bottom: 165px; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; }
  .p-xl-12 {
    padding: 180px; }
  .m-xl-12 {
    margin: 180px; }
  .pt-xl-12 {
    padding-top: 180px; }
  .mt-xl-12 {
    margin-top: 180px; }
  .pb-xl-12 {
    padding-bottom: 180px; }
  .mb-xl-12 {
    margin-bottom: 180px; }
  .px-xl-12 {
    padding-left: 180px;
    padding-right: 180px; }
  .mx-xl-12 {
    margin-left: 180px;
    margin-right: 180px; }
  .py-xl-12 {
    padding-top: 180px;
    padding-bottom: 180px; }
  .my-xl-12 {
    margin-top: 180px;
    margin-bottom: 180px; }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto; } }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-row-reverse {
  flex-direction: row-reverse; }

.flex-direction-column {
  flex-direction: column; }

.flex-direction-column-reverse {
  flex-direction: column-reverse; }

.flex-direction-initial {
  flex-direction: initial; }

.flex-direction-inherit {
  flex-direction: inherit; }

@media (min-width: 576px) {
  .flex-direction-sm-row {
    flex-direction: row; }
  .flex-direction-sm-row-reverse {
    flex-direction: row-reverse; }
  .flex-direction-sm-column {
    flex-direction: column; }
  .flex-direction-sm-column-reverse {
    flex-direction: column-reverse; }
  .flex-direction-sm-initial {
    flex-direction: initial; }
  .flex-direction-sm-inherit {
    flex-direction: inherit; } }

@media (min-width: 768px) {
  .flex-direction-md-row {
    flex-direction: row; }
  .flex-direction-md-row-reverse {
    flex-direction: row-reverse; }
  .flex-direction-md-column {
    flex-direction: column; }
  .flex-direction-md-column-reverse {
    flex-direction: column-reverse; }
  .flex-direction-md-initial {
    flex-direction: initial; }
  .flex-direction-md-inherit {
    flex-direction: inherit; } }

@media (min-width: 992px) {
  .flex-direction-lg-row {
    flex-direction: row; }
  .flex-direction-lg-row-reverse {
    flex-direction: row-reverse; }
  .flex-direction-lg-column {
    flex-direction: column; }
  .flex-direction-lg-column-reverse {
    flex-direction: column-reverse; }
  .flex-direction-lg-initial {
    flex-direction: initial; }
  .flex-direction-lg-inherit {
    flex-direction: inherit; } }

@media (min-width: 1200px) {
  .flex-direction-xl-row {
    flex-direction: row; }
  .flex-direction-xl-row-reverse {
    flex-direction: row-reverse; }
  .flex-direction-xl-column {
    flex-direction: column; }
  .flex-direction-xl-column-reverse {
    flex-direction: column-reverse; }
  .flex-direction-xl-initial {
    flex-direction: initial; }
  .flex-direction-xl-inherit {
    flex-direction: inherit; } }

.h-100 {
  min-height: 100vh; }

@media (min-width: 0) {
  .h-none-100 {
    min-height: 100vh; } }

@media (min-width: 576px) {
  .h-sm-100 {
    min-height: 100vh; } }

@media (min-width: 768px) {
  .h-md-100 {
    min-height: 100vh; } }

@media (min-width: 992px) {
  .h-lg-100 {
    min-height: 100vh; } }

@media (min-width: 1200px) {
  .h-xl-100 {
    min-height: 100vh; } }

.d-inline {
  display: inline; }

.d-block {
  display: block; }

.d-contents {
  display: contents; }

.d-flex {
  display: flex; }

.d-grid {
  display: grid; }

.d-inline-block {
  display: inline-block; }

.d-inline-flex {
  display: inline-flex; }

.d-inline-grid {
  display: inline-grid; }

.d-inline-table {
  display: inline-table; }

.d-list-item {
  display: list-item; }

.d-run-in {
  display: run-in; }

.d-table {
  display: table; }

.d-table-caption {
  display: table-caption; }

.d-table-column-group {
  display: table-column-group; }

.d-table-header-group {
  display: table-header-group; }

.d-table-footer-group {
  display: table-footer-group; }

.d-table-row-group {
  display: table-row-group; }

.d-table-cell {
  display: table-cell; }

.d-table-column {
  display: table-column; }

.d-table-row {
  display: table-row; }

.d-none {
  display: none; }

.d-initial {
  display: initial; }

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline; }
  .d-sm-block {
    display: block; }
  .d-sm-contents {
    display: contents; }
  .d-sm-flex {
    display: flex; }
  .d-sm-grid {
    display: grid; }
  .d-sm-inline-block {
    display: inline-block; }
  .d-sm-inline-flex {
    display: inline-flex; }
  .d-sm-inline-grid {
    display: inline-grid; }
  .d-sm-inline-table {
    display: inline-table; }
  .d-sm-list-item {
    display: list-item; }
  .d-sm-run-in {
    display: run-in; }
  .d-sm-table {
    display: table; }
  .d-sm-table-caption {
    display: table-caption; }
  .d-sm-table-column-group {
    display: table-column-group; }
  .d-sm-table-header-group {
    display: table-header-group; }
  .d-sm-table-footer-group {
    display: table-footer-group; }
  .d-sm-table-row-group {
    display: table-row-group; }
  .d-sm-table-cell {
    display: table-cell; }
  .d-sm-table-column {
    display: table-column; }
  .d-sm-table-row {
    display: table-row; }
  .d-sm-none {
    display: none; }
  .d-sm-initial {
    display: initial; } }

@media (min-width: 768px) {
  .d-md-inline {
    display: inline; }
  .d-md-block {
    display: block; }
  .d-md-contents {
    display: contents; }
  .d-md-flex {
    display: flex; }
  .d-md-grid {
    display: grid; }
  .d-md-inline-block {
    display: inline-block; }
  .d-md-inline-flex {
    display: inline-flex; }
  .d-md-inline-grid {
    display: inline-grid; }
  .d-md-inline-table {
    display: inline-table; }
  .d-md-list-item {
    display: list-item; }
  .d-md-run-in {
    display: run-in; }
  .d-md-table {
    display: table; }
  .d-md-table-caption {
    display: table-caption; }
  .d-md-table-column-group {
    display: table-column-group; }
  .d-md-table-header-group {
    display: table-header-group; }
  .d-md-table-footer-group {
    display: table-footer-group; }
  .d-md-table-row-group {
    display: table-row-group; }
  .d-md-table-cell {
    display: table-cell; }
  .d-md-table-column {
    display: table-column; }
  .d-md-table-row {
    display: table-row; }
  .d-md-none {
    display: none; }
  .d-md-initial {
    display: initial; } }

@media (min-width: 992px) {
  .d-lg-inline {
    display: inline; }
  .d-lg-block {
    display: block; }
  .d-lg-contents {
    display: contents; }
  .d-lg-flex {
    display: flex; }
  .d-lg-grid {
    display: grid; }
  .d-lg-inline-block {
    display: inline-block; }
  .d-lg-inline-flex {
    display: inline-flex; }
  .d-lg-inline-grid {
    display: inline-grid; }
  .d-lg-inline-table {
    display: inline-table; }
  .d-lg-list-item {
    display: list-item; }
  .d-lg-run-in {
    display: run-in; }
  .d-lg-table {
    display: table; }
  .d-lg-table-caption {
    display: table-caption; }
  .d-lg-table-column-group {
    display: table-column-group; }
  .d-lg-table-header-group {
    display: table-header-group; }
  .d-lg-table-footer-group {
    display: table-footer-group; }
  .d-lg-table-row-group {
    display: table-row-group; }
  .d-lg-table-cell {
    display: table-cell; }
  .d-lg-table-column {
    display: table-column; }
  .d-lg-table-row {
    display: table-row; }
  .d-lg-none {
    display: none; }
  .d-lg-initial {
    display: initial; } }

@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline; }
  .d-xl-block {
    display: block; }
  .d-xl-contents {
    display: contents; }
  .d-xl-flex {
    display: flex; }
  .d-xl-grid {
    display: grid; }
  .d-xl-inline-block {
    display: inline-block; }
  .d-xl-inline-flex {
    display: inline-flex; }
  .d-xl-inline-grid {
    display: inline-grid; }
  .d-xl-inline-table {
    display: inline-table; }
  .d-xl-list-item {
    display: list-item; }
  .d-xl-run-in {
    display: run-in; }
  .d-xl-table {
    display: table; }
  .d-xl-table-caption {
    display: table-caption; }
  .d-xl-table-column-group {
    display: table-column-group; }
  .d-xl-table-header-group {
    display: table-header-group; }
  .d-xl-table-footer-group {
    display: table-footer-group; }
  .d-xl-table-row-group {
    display: table-row-group; }
  .d-xl-table-cell {
    display: table-cell; }
  .d-xl-table-column {
    display: table-column; }
  .d-xl-table-row {
    display: table-row; }
  .d-xl-none {
    display: none; }
  .d-xl-initial {
    display: initial; } }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

@media (min-width: 576px) {
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; } }

@media (min-width: 768px) {
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; } }

@media (min-width: 992px) {
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; } }

@media (min-width: 1200px) {
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; } }

.visible {
  opacity: 1 !important; }

.opacity-0 {
  opacity: 0;
  transition: opacity .35s ease .5s; }

.h-overlay {
  height: 100%; }

.h-70 {
  min-height: 70vh; }

.txt-center {
  text-align: center; }

.lemon {
  color: #a6bf00; }

img {
  display: block;
  width: 100%; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0; }

.about, .work, .contact, .personal-projects {
  z-index: 10; }

#root {
  overflow: hidden; }
